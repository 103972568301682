a{
    text-decoration: none !important;
}

.notification{
    padding: 50px;
}
.css-yk16xz-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border: 1px solid #f0f1f5 !important;
    border-radius: 12px !important;
}
.css-1pahdxg-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border-color: #2684ff !important;
    border-radius: 12px !important;
    box-shadow: 0 0 0 0 #2684ff !important;
}
/* ._loading_overlay_content {
padding-right: 350px;
padding-bottom: 350px;
} */
.user-select{
    border: 1px solid #f0f1f5 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-radius: 12px !important;
}
.hidden-button{
    width: 30% !important;
  }
select:required:invalid {
    color: gray;
  }
.rs-loader-backdrop {
   
    background: rgb(255 255 255 / 70%) !important;
}
.css-tlfecz-indicatorContainer{
    padding-right: 0px !important;
}
.card-header {
    border-color: #f0f1f5;
    position: relative;
    background: transparent;
    padding: 1.5rem 1.875rem 1.25rem;
    display: block;
    align-items: center;
}